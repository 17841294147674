import React from 'react'
import { FaLinkedinIn } from "react-icons/fa";
import { RiFacebookLine } from "react-icons/ri";
import { FiInstagram } from "react-icons/fi";
import { AiFillYoutube } from "react-icons/ai";
const Footer = () => {
  return (
    <div>
        <hr />
      <div className="footer">
        <div className="services">
          <a href="/">
            {" "}
            <li>Service</li>
          </a>
          <a href="/">
            <li>Help Center</li>
          </a>
          <a href="/">
            <li>Partner</li>
          </a>
        </div>
        <div className="social-icons">
          <a href="/" className="socials">
            <FaLinkedinIn />{" "}
          </a>
          <a href="/" className="socials">
            {" "}
            <RiFacebookLine />{" "}
          </a>
          <a href="/" className="socials">
            {" "}
            <FiInstagram />{" "}
          </a>
          <a href="/" className="socials">
            {" "}
            <AiFillYoutube />{" "}
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer