import React, {useState} from 'react'
import Logo from '../Image/logo.svg'
import '../Stylesheet/Nav.css'
import { Link } from 'react-router-dom'


const Navbar = () => {
    
  return (
    <div>
        <header>
        <div className="navbar ">
          <div
            className="container nav-container"
          >
            <input className="checkbox" type="checkbox" name="" id="" />
            <div className="hamburger-lines">
              <span clasNames="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
            <div className="logo">
            {" "}
            <a className="aimg hidden md:flex" href="index.html">
              <img
                src={Logo}
              />
            </a>
            </div>

            <ul className="menu-items menu-options">
            <a href="#work">
              <li li className="wonda-menu">
               How It Works 
              </li>
              </a>
              <a href="mailto:mayowa@uniswitchng.com" className="wonda-menu">
              <li li className="wonda-menu">
                 Contact Us 
              </li>
              </a>
            </ul>
        {/* <div className="menu-header container">
          <div className="logo">
            {" "}
            <a href="/" data-toggle="dropdown">
              <img src={Logo} alt="" />{" "}
            </a>
          </div>
          <ul className="menu-options" open={open}>
            <a
              className="wonda-menu"
              href='#work'
            >
              <li className="wonda-menu"> How It Works </li>
            </a>
            <a href="/" className="wonda-menu">
              <li>Features</li>
            </a>

            <a href="mailto:mayowa@uniswitchng.com" className="wonda-menu">
              <li>Contact Us</li>
            </a>
          </ul>
          <input class="checkbox hidden" type="checkbox" name="" id="" />
          <div className="hambuger-lines">
            <span className="ine line1"></span>
            <span className="ine line1"></span>
            <span className="ine line1"></span>
          </div>
         </div> */}
         </div>
        </div>
      </header>
    </div>
  )
}

export default Navbar