import React from "react";
import { useState } from "react";
// import Logo from "../Image/logo.svg";
import "../Stylesheet/Home.css";
import { BsArrowDownCircle } from "react-icons/bs";
import Phone from "../Image/Phone01.svg";
import Card from "../Image/nfc-technology-concept-2022-08-10-12-02-35-utc.mp4";
import { AiOutlineArrowRight } from "react-icons/ai";
import Nfc from "../Image/nfc-payment.png";
import Pos from "../Image/pos.png";
import Finger from "../Image/finger.png";

import Play from "../Image/play.svg";
import Apple from "../Image/apple.svg";
// import { useRef } from "react";
// import Data from "../Work/Work";
import { NavLink } from "react-router-dom";
import Navbar from "../Component/Navbar";
import Footer from "./Footer";

const Home = () => {
  return (
    <div className="homebody">
      {/* <header>
        <div className="menu-header">
          <div className="logo">
            {" "}
            <a href="/" data-toggle="dropdown">
              <img src={Logo} alt="" />{" "}
            </a>
          </div>
          <ul className="menu-options">
            <NavLink
              className="wonda-menu"
              to="/work"
              onClick={() => {
                setShowWork(!showWork);
              }}
            >
              <li className="wonda-menu"> How It Works </li>
            </NavLink>
            <a href="/" className="wonda-menu">
              <li>Features</li>
            </a>

            <a href="mailto:mayowa@uniswitchng.com" className="wonda-menu">
              <li>Contact Us</li>
            </a>
          </ul>
          <div class="hamburger" onClick={toggleHamburger}>
            <span className="burger"></span>
            <span className="burger"></span>
            <span className="burger"></span>
          </div>
        </div>
      </header> */}
      <Navbar />

      {/* -----section2 */}

      <div className="powering-section">
        <div className="scroll">
          <a className="scroll-down" href="#scro">
            SCROLL DOWN
          </a>
          <BsArrowDownCircle className="circle" />
        </div>
        <div className="phone-section">
          <img className="phone" src={Phone} alt="" />
        </div>
        <div>
          <div className="phone-text">
            <h1 className="powering-header">
              We are powering the next generation of contactless payments.
            </h1>
            <p className="powering-para">
              Wonda is an NFC wallet system for storing credit and debit cards
              on mobile phones and allowing them to be used for payments via
              NFC, in apps, or on the web.
            </p>
          </div>
          <div className="download">
            <div className="play-store">
              <img src={Play} alt="" />
              <div className="store">
                <p className="store-para">Coming Soon</p>
                <h6 className="google-store">Google Play</h6>
              </div>
            </div>
            <div className="play-store">
              <img src={Apple} alt="" />
              <div className="store">
                <p className="store-para">Coming Soon</p>
                <h6 className="google-store">Apple Store</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //---transaction */}
      <div className="transactions">
        <div className="debit-credit">
          <h2 className="won">
            Wonda is an app that stores Debit/Credit cards and data
          </h2>
          <h6 className="tap-transaction">Tap to pay transactions</h6>
          <p className="tap-text">
            Tap to pay transactions are often highly praised by both customers
            and retailers. Some suppliers have claimed that tap to pay
            transactions are up to twice as fast as traditional methods.
          </p>
          <a href="/">
            <h5 className="learn">Read More</h5>
          </a>
        </div>
        <div className="img-transactions">
          <img className="nfc" src={Nfc} alt="" />
        </div>
      </div>

      {/* ---Transaction payment */}
      <div className="transactions transactions3" id="scro">
        <div className="img-transactions">
          <img className=" nfc2" src={Pos} alt="" />
        </div>
        <div className="debit">
          <h2 className="totap">Tap to pay transactions</h2>
          <p className="tapto">
            Tap to pay transactions are often highly praised by both customers
            and retailers. Some suppliers have claimed that tap to pay
            transactions are up to twice as fast as traditional methods.
          </p>
          <a href="/">
            <h5 className="learn">Learn More</h5> <AiOutlineArrowRight />
          </a>
        </div>
      </div>

      {/* ----section3 */}
      <div className="wireless">
        <h2 className="more">Do more with your money.</h2>
        <p className="text-wireless">
          By using a Phone's near field communications (NFC) chip, Wonda can
          wirelessly communicate with point-of-sale systems to initiate a
          contactless payment, or what is often referred to as "tap to pay"
          transactions
        </p>
      </div>
      <div className="black-card">
        <iframe
          className=""
          src="https://wonda-website-asset.s3.amazonaws.com/nfc-technology-concept-2022-08-10-12-02-35-utc.mp4"
          frameborder="0"
          allowfullscreen
          width="560"
          height="315"
        ></iframe>
        {/* <video>
    <source src="/nfc-technology-concept-2022-08-10-12-02-35-utc.mp4" type="video/mp4" />

    Sorry, your browser doesn't support videos.
</video> */}
      </div>

      {/* ---Encrypted transactions */}
      <div className="transactions">
        <div className="debit-credit">
          <h2 className="tap-transaction2">ALL transactions are encrypted</h2>
          <p className="tap-text2">
            1. The Wonda App is password protected (biometrics and or device PIN)
            <br /> 2. ALL transactions require a PIN (Every transaction is
            authenticated by your fingerprint, PIN or facial recognition.)
          </p>
          <a href="/">
            <h5 className="learn">Learn More</h5> <AiOutlineArrowRight />
          </a>
        </div>
        <div className="img-transactions">
          <img className="img-tra" src={Finger} alt="" />
        </div>
      </div>

      {/* ---information section */}

      <div className="request">
        <p className="join">JOIN FOR A BETTER FUTURE</p>
        <h2 className="info">Request More Information</h2>
        <p className="wonda-text">
          Wonda is the one way to pay. It replaces your physical cards and cash
          with an easier, safer, more secure, and private payment method —
          whether you’re in a store, online, or sending cash to friends or
          family.
        </p>
        <a href="mailto:mayowa@uniswitchng.com">
          <button className="butt">CONTACT US</button>
        </a>
      </div>

      {/* -------Download----------- */}
      <div className="download">
        <div className="play-store">
          <img src={Play} alt="" />
          <div className="store">
            <p className="store-para">Coming Soon</p>
            <h6 className="google-store">Google Play</h6>
          </div>
        </div>
        <div className="play-store">
          <img src={Apple} alt="" />
          <div className="store">
            <p className="store-para">Coming Soon</p>
            <h6 className="google-store">Apple Store </h6>
          </div>
        </div>
      </div>
      <div className="how" id="work">
        <h2 className="how-header">How It Works</h2>
        <p>
          Wonda is an app that stores Debit/Credit cards and data. By using a
          Phone's near field communications (NFC) chip, Wonda can wirelessly
          communicate with point-of-sale systems to initiate a contactless
          payment, or what is often referred to as "tap to pay" transactions.
          Tap to pay transactions are often highly praised by both customers and
          retailers. Some suppliers have claimed that tap to pay transactions
          are up to twice as fast as traditional methods. During a sale, a buyer
          will activate the Wonda app on the phone, after they select which card
          to use, a buyer must hold their device near a point-of-sale terminal.
          The device will provide the necessary information, such as the device
          ID and a tokenized card number, to the terminal. The buyer will need
          to authenticate the sale, usually either through a PIN or Face ID or
          Touch ID. At no point is the buyer's card information shared with the
          merchant, as the original card number is not stored on the device
          anyway.
        </p>
        <a href="/">
          <h5 className="learn">Learn More</h5> <AiOutlineArrowRight className="work-arrow" />
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
