import React from 'react'
import Navbar from '../Component/Navbar'
import '../Stylesheet/Work.css'
import '../Stylesheet/Home.css'
import Footer from './Footer'
const Work = () => {
  return (
    
    <div className='work-body'>
      <div className="work-nav">
      <Navbar />
      </div>
   
    <div className='how'>
        <p>Wonda is an app that stores Debit/Credit 
cards and data. 
By using a Phone's near field communications
(NFC) chip, Wonda can wirelessly communicate
 with point-of-sale systems to initiate a contactless 
payment, or what is often referred to as 
"tap to pay" transactions.
Tap to pay transactions are often highly praised 
by both customers and retailers. Some suppliers
 have claimed that tap to pay transactions are up 
to twice as fast as traditional methods. 
During a sale, a buyer will activate the Wonda
app on the phone, after they select which card to
use, a buyer must hold their device near a 
point-of-sale terminal. The device will provide 
the necessary information, such as the device ID 
and a tokenized card number, to the terminal.
The buyer will need to authenticate the sale,
usually either through a PIN or Face ID or Touch ID. 
At no point is the buyer's card information shared 
with the merchant, as the original card number is
not stored on the device anyway.</p>
    </div>
    <Footer />
    </div>
  )
}

export default Work